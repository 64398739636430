<script>
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import CheckoutAcknowledgement from '/~/views/checkout.v2/components/checkout/acknowledgement.vue'
import CheckoutSummary from '/~/views/checkout.v2/components/checkout/checkout-summary.mobile.vue'
import CheckoutDesktop from './../checkout.desktop.vue'

export default {
  name: 'checkout-mobile',
  components: {
    BaseAsidePage,
    CheckoutSummary,
    CheckoutAcknowledgement,
  },
  extends: CheckoutDesktop,
  setup() {
    return {
      ...CheckoutDesktop.setup?.(...arguments),
    }
  },
}
</script>

<template>
  <base-aside-page title="Payment summary" no-padding shadow>
    <checkout-summary class="mx-auto" />

    <checkout-acknowledgement class="p-4" />

    <div class="z-40 w-full bg-white px-5 shadow-md">
      <div class="w-full">
        <message-panel
          v-show="showAdditionalPaymentMsg && additionalPaymentMsg.show"
          class="w-full py-4"
          type="warning"
          icon="plain/alert"
          dont-close
        >
          {{ additionalPaymentMsg.message }}
        </message-panel>
      </div>

      <div class="mx-auto max-w-lg py-5">
        <div class="mb-[15px] flex justify-between">
          <span class="text-2xl font-bold">Total</span>
          <span class="text-right text-2xl font-bold">
            {{ formatDollar(payment.total) }}
          </span>
        </div>

        <div class="flex justify-center gap-2.5">
          <base-button look="outlined-color" class="flex-1" @click="onCancel()">
            Cancel
          </base-button>

          <base-button
            class="flex-1"
            :disabled="!payment.readyToPay || !payment.isTermsAccepted"
            :loading="payment.loadingFees || processing"
            @click="onSubmit"
          >
            {{ payment.date ? 'Schedule' : 'Pay' }}
          </base-button>
        </div>
      </div>
    </div>
  </base-aside-page>
</template>
